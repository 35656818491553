<script setup lang="ts">
import type { HomeJackpots, Game } from "@/types";

const DEFAULT_INCREMENT_STEP = 0.15;
const DEFAULT_INCREMENT_INTERVAL = 550;

const props = defineProps<{
	gameMode?: string;
	jackpots?: HomeJackpots;
	games: Game[];
}>();

const { t } = useT();
const { isDesktop } = useDevice();

const intervalId = ref<ReturnType<typeof setInterval>>();
const amount = ref(0);
const arraybleAmount = ref<string[]>(["0"]);

const isSweepStakes = computed(() => props.gameMode === "SweepStakes");
const totalWinAmount = computed(() => {
	const values = props.jackpots?.[isSweepStakes.value ? "USD" : "TRN"]?.values ?? [];
	return values.reduce((acc, item) => acc + (item.amount ?? 0), 0) * 100;
});

const incrementValue = () => {
	amount.value += DEFAULT_INCREMENT_STEP;
	if (isSweepStakes.value) {
		arraybleAmount.value = numberFormat(amount.value, { minimumFractionDigits: 2 }).replace(/\.\d*/, "").split("");
		return;
	}
	arraybleAmount.value = numberFormat(amount.value * 100).split("");
};

watch(isSweepStakes, () => {
	amount.value = totalWinAmount.value;
});

onMounted(() => {
	amount.value = totalWinAmount.value;
	intervalId.value = setInterval(incrementValue, DEFAULT_INCREMENT_INTERVAL);
});
onUnmounted(() => clearInterval(intervalId.value));
</script>

<template>
	<section class="jackpot">
		<div class="logo">
			<NuxtImg
				class="logo-img"
				src="/nuxt-img/jackpot/jackpot-logo.png"
				format="avif"
				width="642"
				height="370"
				alt="logo"
				data-tid="slider-title-jackpot-games"
				densities="1x"
				quality="100"
			/>
			<NuxtImg
				:src="`/nuxt-img/jackpot/jackpot-coins-${isDesktop ? 'desk' : 'mob'}.png`"
				format="avif"
				:width="isDesktop ? 507 : 352"
				:height="isDesktop ? 250 : 191"
				class="coins"
				alt="coins"
				loading="lazy"
			/>
		</div>

		<div class="values">
			<NuxtImg
				src="/nuxt-img/jackpot/icon.png"
				format="avif"
				width="48"
				height="47"
				class="icon"
				alt="icon"
				loading="lazy"
			/>
			<AText
				class="amount"
				:size="{ full: 46, md: 32 }"
				:modifiers="['bold', 'center', 'nowrap', 'condensed']"
				as="div"
				data-tid="jackpot-value"
			>
				<TransitionGroup name="counter">
					<span
						v-for="(item, index) in arraybleAmount"
						:key="item + index"
						:class="['amount-item', { 'is-separator': isNaN(parseInt(item)) }]"
					>
						{{ isNaN(parseInt(item)) ? "," : item }}
					</span>
				</TransitionGroup>
			</AText>
		</div>
		<div class="games">
			<LazyOHomeGames
				:games="games"
				:hideNextSlidesMobile="true"
				:rows-per-slide="isDesktop ? 1 : 2"
				:isShowAll="false"
			/>
		</div>
		<NuxtLink to="/issues/jackpot/" class="button">
			<AButton variant="primary" size="xl">
				<AText variant="toledo" data-tid="jackpot-show-all" :modifiers="['capitalize']">
					{{ t("All games") }}
				</AText>
			</AButton>
		</NuxtLink>
		<NuxtImg
			v-if="isDesktop"
			class="stones"
			src="/nuxt-img/jackpot/stones.png"
			format="avif"
			width="1303"
			height="141"
			alt="logo"
			loading="lazy"
		/>
	</section>
</template>

<style scoped lang="scss">
.jackpot {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	margin: 100px 16px 24px;
	background:
		url("/nuxt-img/jackpot/bg-m.jpg") no-repeat top center / cover,
		var(--neutral-900);
	border-radius: 16px;
	padding: 10px;
	height: 455px;
	@include media-breakpoint-up(md) {
		background:
			url("/nuxt-img/jackpot/bg.jpg") no-repeat top center / cover,
			var(--neutral-900);
		height: 343px;
		margin: 150px 0 44px;
		padding: 80px;
		gap: 16px;
	}

	.logo {
		position: absolute;
		top: -135px;
		@include media-breakpoint-up(lg) {
			top: -161px;
		}
		.logo-img {
			width: 378px;
			height: auto;

			@include media-breakpoint-up(lg) {
				width: 428px;
			}
		}
		.coins {
			position: absolute;
			top: 30px;
			left: 0;
			z-index: 2;
			animation: stones 5s 3s ease-in-out infinite;
			@include media-breakpoint-up(lg) {
				top: 40px;
			}
		}
		img {
			width: 100%;
			height: auto;
		}
	}

	.values {
		display: flex;
		align-items: center;
		gap: 8px;
		border-radius: 16px;
		border: 1px solid rgba(var(--gray-950-rgb), 0.4);
		background: rgba(var(--gray-950-rgb), 0.4);
		padding: 0 8px;
		margin: 19px 0 -19px;
		@include media-breakpoint-down(md) {
			margin: 16px auto -8px;
			.icon {
				width: 32px;
				height: 32px;
			}
		}
		.amount {
			padding: 8px 0;
			text-shadow: 0 1px 7px rgba(var(--neutral-950-rgb), 0.85);
			position: relative;
			overflow: hidden;
			color: var(--neutral-50);

			&-item:not(.is-separator) {
				transition: all 0.55s ease;
				display: inline-block;
				width: 30px;

				@include media-breakpoint-down(md) {
					width: 20px;
				}
			}
		}
	}

	.content {
		width: 720px;
		max-width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		z-index: 1;

		@include media-breakpoint-down(md) {
			margin-top: 16px;
		}
	}

	.games {
		max-width: 100%;
		section {
			padding: 0;
		}
		:deep(.card-wrapper) {
			width: 108px;
			@include media-breakpoint-up(lg) {
				width: 118px;
			}
		}
		@include media-breakpoint-down(lg) {
			:deep(.keen-slider) {
				overflow: hidden;
				.keen-slider__slide {
					width: 118px;
				}
			}
		}
	}
	.button {
		position: absolute;
		bottom: -24px;
		button {
			width: 296px;
			max-width: 100%;
			@include media-breakpoint-up(md) {
				width: 250px;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.stones {
			position: absolute;
			top: 10px;
			width: 90%;
			height: auto;
			animation: stones 4s ease-in-out infinite;
		}
	}
}
.counter {
	&-enter-from,
	&-leave-to {
		opacity: 0;
	}

	&-enter-from {
		transform: translateY(40px);
	}

	&-leave-active {
		position: absolute;
	}

	&-leave-to {
		transform: translateX(100%) translateY(-40px);
	}
}

@keyframes stones {
	50% {
		transform: scale(1.06);
	}
}
</style>
